<template>
  <div
    class="d-flex items-center mb-2"
    v-if="field.Form_Label"
    :style="sectionStyle"
  >
    <div class="flex-1">
      {{ field.Form_Label }}
    </div>
  </div>
  <n-data-table
    class="mb-3"
    :columns="columns"
    :data="data"
    :pagination="false"
  />
</template>

<script>
import { h } from "vue";
import { getSettings } from "../../assets/js/common.helper";
import { NInput } from "naive-ui";
export default {
  props: {
    field: {},
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    columns() {
      const vm = this;
      let columns = getSettings({ ...vm.field }).columns || [];
      columns = columns.map((i) => ({
        key: i.label,
        title: i.label,
        render: (row, index) => {
          if (row[i.label] && i.type == "Image") {
            return h("img", {
              src: row[i.label],
              style: { width: "100px" },
            });
          } else if (i.is_editable) {
            let type = i.type == "Number" ? "number" : "text";
            return h(NInput, {
              value: row[i.label],
              type,
              onUpdateValue(v) {
                vm.data[index][i.label] = v;
                vm.$emit("input", vm.data);
              },
            });
          } else {
            return row[i.label];
          }
        },
      }));
      return columns;
    },
  },
  methods: {},
  mounted() {
    if (this.value && this.value.length) {
      this.data = this.value;
    } else {
      this.data = getSettings({ ...this.field }).table_rows || [];
      this.$emit("input", this.data);
    }
  },
};
</script>

<style></style>
