<template>
  <n-form-item
    :label="label"
    :label-style="labelStyle"
    :path="path"
    class="flex-1"
    :rule="rule"
  >
    <template v-slot:label>
      <span class="mr-1">
        {{ label }}
      </span>
      <help-text v-if="help"> {{ help }}</help-text>
    </template>
    <n-input
      v-bind="$attrs"
      v-model:value="input"
      clearable
      :class="unit ? 'unit-field' : ''"
      @input="$emit('input', input)"
      :placeholder="placeholder"
      :type="type"
    >
      <template #suffix>
        <n-button v-if="unit" type="tertiary"> {{ unit }}</n-button>
        <n-icon v-if="icon" :component="icon" />
      </template>
    </n-input>
  </n-form-item>
</template>

<script>
import { Call, LinkOutline, Mail } from "@vicons/ionicons5/";
import { CurrencyRupeeTwotone, NumbersRound } from "@vicons/material";
import HelpText from "../HelpText.vue";
import { getSettings } from "../../../assets/js/common.helper";

export default {
  components: { HelpText },
  inheritAttrs: false,
  props: {
    field: { type: Object, default: () => ({}) },
    value: {},
    help: { type: String, default: "" },
    type: { type: String, default: "text" },
    placeholder: { type: String, default: "" },
    path: {},
    customRules: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    label: { type: String, default: "" },
  },
  data() {
    return {
      input: "",
      Call,
      Mail,
      NumbersRound,
    };
  },
  computed: {
    unit() {
      let settings = getSettings({ ...this.field });
      return settings.unit;
    },
    rule() {
      let { rules } = this;
      const vm = this;
      let settings = this.settings || {};
      return {
        trigger: ["submit", "blur", "input"],
        required: settings.is_required || false,
        validator() {
          for (let rule of rules) {
            let msg = rule({ v: vm.input, ...vm.settings });
            if (msg) return new Error(msg);
          }
        },
      };
    },
    icon() {
      switch (this.type) {
        case "number":
          return NumbersRound;
        case "email":
          return Mail;
        case "phone":
          return Call;
        case "currency":
          return CurrencyRupeeTwotone;
        case "url":
          return LinkOutline;
        default:
          return "";
      }
    },
  },
  watch: {
    value(v) {
      this.input = v;
    },
  },
  mounted() {
    this.input = this.value || "";
  },
};
</script>

<style lang="scss">
.input-feedback {
  .n-form-item {
    grid-template-rows: none !important;
  }
  .n-form-item-blank,
  .n-form-item-label {
    display: none !important;
  }
}
</style>