export default {
  rules: {
    number: ({ v, is_required }) =>
      (is_required ? isNaN(v) : v && isNaN(v)) ? "Field must be number" : "",
    email: ({ v, is_required }) =>
      (is_required ? !/.+@.+/.test(v) : v && !/.+@.+/.test(v))
        ? "Please enter valid email"
        : "",
    alpha: ({ v, is_required }) =>
      (
        is_required
          ? !v.match(/^[a-zA-Z!@#$%^&*.,-="()]+$/)
          : v && !v.match(/^[a-zA-Z!@#$%^&*.,-="()]+$/)
      )
        ? "Field must be alphabetc"
        : "",
    alphaNumeric: ({ v, is_required }) =>
      (
        is_required
          ? !v.match(/^[a-zA-Z0-9!@#$%^&*.,-="()]+$/)
          : v && !v.match(/^[a-zA-Z0-9!@#$%^&*.,-="()]+$/)
      )
        ? "Field must be alphabetc or numeric"
        : "",
    phone: ({ v, is_required }) =>
      (is_required ? v.length != 10 : v && v.length != 10)
        ? "Please enter valid phone"
        : "",
    minChar: ({ v, min_char }) =>
      v && v.length < Number(min_char)
        ? `Minimum ${min_char} character is required`
        : "",
    maxChar: ({ v, max_char }) =>
      v && v.length > Number(max_char)
        ? `Maximum ${max_char} character is allowed`
        : "",
    minNumber: ({ v, min_char }) =>
      v && v < Number(min_char) ? `Minimum ${min_char} value is required` : "",
    maxNumber: ({ v, max_char }) =>
      v && v > Number(max_char) ? `Maximum ${max_char} value is allowed` : "",
    disableFutureDate: ({ v }) => {
      return v && v > new Date().getTime() ? `Future date is not allowed` : "";
    },
    disableFutureDateTime: ({ v }) => {
      return v && v > new Date().getTime()
        ? `Future date and time is not allowed`
        : "";
    },
    required: ({ v }) => (v == "" || v == undefined ? "Field is required" : ""),
  },
  required: {
    required: true,
    trigger: ["submit", "blur", "input"],
    validator(rule, v) {
      if (v === "" || v == undefined) {
        return new Error("Field is required");
      }
    },
  },
  urlRequired: {
    required: true,
    trigger: ["submit", "blur", "input"],
    validator(rule, v) {
      if (v === "" || v == undefined) {
        return new Error("Field is required");
      }
      const urlRegex = /^(https?:\/\/)?(www\.)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
      if (!urlRegex.test(v)) {
        return new Error("URL Must be valid");
      }
    },
  },
  emailOptional: {
    required: false,
    trigger: ["submit", "blur", "input"],
    type: "email",
    validator(rule, v) {
      if (/.+@.+/.test(v)) {
        return new Error("E-mail must be valid");
      }
      return true;
    },
  },
  email: {
    required: true,
    trigger: ["submit", "blur", "input"],
    type: "email",
    validator(rule, v) {
      if (/.+@.+/.test(v)) {
        return new Error("E-mail must be valid");
      }
      return true;
    },
  },
};
