import {
  CalendarNumber,
  Call,
  Checkbox,
  ChevronDownCircle,
  CloudUpload,
  LinkOutline,
  Mail,
  RadioButtonOnSharp,
  Text,
  TimeOutline,
} from "@vicons/ionicons5";
import {
  EditRound,
  GridViewRound,
  LayersRound,
  NumbersRound,
  TextSnippetOutlined,
  ShortTextOutlined,
  ViewColumnOutlined,
  AmpStoriesOutlined,
  CurrencyRupeeTwotone,
  QuestionMarkFilled,
  ListAltFilled,
  TableRowsOutlined,
  ImageRound,
  ViewHeadlineOutlined,
} from "@vicons/material";
import { NIcon } from "naive-ui";
import { h } from "vue";
const renderIcon = (icon) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon),
    });
  };
};
export const fieldTypes = [
  {
    label: "Text",
    key: "text",
    icon: renderIcon(Text),
  },
  {
    label: "TextArea",
    key: "textarea",
    icon: renderIcon(TextSnippetOutlined),
  },
  {
    label: "Number",
    key: "number",
    icon: renderIcon(NumbersRound),
  },
  {
    label: "Email",
    key: "email",
    icon: renderIcon(Mail),
  },
  {
    label: "Phone",
    key: "phone",
    icon: renderIcon(Call),
  },
  {
    label: "Currency",
    key: "currency",
    icon: renderIcon(CurrencyRupeeTwotone),
  },

  {
    label: "URL",
    key: "url",
    icon: renderIcon(LinkOutline),
  },

  {
    label: "Date",
    key: "date",
    icon: renderIcon(CalendarNumber),
  },
  {
    label: "Date Time",
    key: "datetime",
    icon: renderIcon(TimeOutline),
  },
  {
    label: "Time Picker",
    key: "time",
    icon: renderIcon(TimeOutline),
  },
  {
    label: "Single Selection",
    key: "radio",
    icon: renderIcon(RadioButtonOnSharp),
  },
  {
    label: "Questioner",
    key: "questioner",
    icon: renderIcon(QuestionMarkFilled),
  },
  {
    label: "Questioner Result",
    key: "questioner-result",
    icon: renderIcon(ListAltFilled),
  },
  {
    label: "Checkbox",
    key: "checkbox",
    icon: renderIcon(Checkbox),
  },
  {
    label: "Checkbox Group",
    key: "checkbox-group",
    icon: renderIcon(Checkbox),
  },
  {
    label: "Dropdown Selection",
    key: "select",
    icon: renderIcon(ChevronDownCircle),
  },
  {
    label: "File Upload",
    key: "file-upload",
    icon: renderIcon(CloudUpload),
  },
  {
    label: "Signature",
    key: "signature",
    icon: renderIcon(EditRound),
  },
  {
    label: "Rich Editor",
    key: "rich-editor",
    icon: renderIcon(ShortTextOutlined),
  },

  {
    label: "Explanation",
    key: "paragraph",
    icon: renderIcon(ShortTextOutlined),
  },
  {
    label: "Table",
    key: "table",
    icon: renderIcon(TableRowsOutlined),
  },
  {
    label: "Section",
    key: "section",
    icon: renderIcon(LayersRound),
  },
  {
    label: "Container",
    key: "container",
    icon: renderIcon(AmpStoriesOutlined),
  },
  {
    label: "Image",
    key: "image",
    icon: renderIcon(ImageRound),
  },
  // {
  //     label: "Collapsible Section",
  //     key: "collapsible-section",
  //     icon: "mdi-collapse-all",
  // },
  {
    label: "Grid (multi column fields)",
    key: "grid",
    icon: renderIcon(GridViewRound),
  },
  {
    label: "Empty Cell",
    key: "empty-cell",
    icon: renderIcon(ViewColumnOutlined),
  },
  {
    label: "Header",
    key: "header",
    icon: renderIcon(ViewHeadlineOutlined),
  },
  {
    label: "Footer",
    key: "footer",
    icon: renderIcon(ViewHeadlineOutlined),
  },
];
export const nonSettingFields = ["empty-cell", "paragraph", "container"];
export const textFields = [
  "text",
  "number",
  "currency",
  "email",
  "phone",
  "textarea",
  "url",
];
export const characterFields = ["text", "currency", "textarea"];

export const optionFields = ["radio", "select", "checkbox-group"];
export const multiSelectFields = ["select", "checkbox-group"];
export const conditionalFields = [
  "text",
  "number",
  "textarea",
  "currency",
  "email",
  "phone",
  "checkbox",
  "radio",
  "select",
  "checkbox-group",
];
export const textConditions = ["Starts With", "Ends With", "Is", "Is Not"];
export const optionConditions = ["Selected", "Is Not Selected"];
export const characterTypes = [
  {
    label: "Alpha",
    value: "alpha",
  },
  {
    label: "Alpha Numeric",
    value: "alpha_numeric",
  },
  {
    label: "Numeric",
    value: "numeric",
  },
];
export const fileTypes = [
  { label: "PDF", value: ".pdf" },
  { label: "DOC", value: ".doc" },
  { label: "DOCX", value: ".docx" },
  { label: "XLS", value: ".xls" },
  { label: "XLSX", value: ".xlsx" },
  { label: "PPT", value: ".ppt" },
  { label: "DCM", value: ".dcm" },
  { label: "PPTX", value: "pptx" },
  { label: "ZIP", value: "zip" },
  { label: "RAR", value: "rar" },
  { label: "MP3", value: "mp3" },
  { label: "WAV", value: "wav" },
  { label: "MP4", value: "mp4" },
  { label: "WMV", value: "wmv" },
  { label: "MOV", value: "mov" },
  { label: "JPG", value: ".jpg" },
  { label: "JPEG", value: ".jpeg" },
  { label: "GIF", value: "gif" },
  { label: "PNG", value: ".png" },
  { label: "TIFF", value: "tiff" },
  { label: "TIF", value: "tif" },
  { label: "PSD", value: "psd" },
];

export const FormTypes = {
  StepForm: 1,
  SingleForm: 2,
};
