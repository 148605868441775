<template>
  <div class="dashed-border p-3 mb-3">
    <div class="d-flex pb-3">
      <div class="flex-1" :style="sectionStyle">
        {{ field.Form_Label }}
      </div>
      <div class="flex-1"></div>
      <fb-add-table-row class="mr-3" :field="field" />
      <fb-field-config :field="field"></fb-field-config>
    </div>
    <n-data-table :columns="columns" :data="data" :pagination="false" />
  </div>
</template>

<script>
import { h } from "vue";
import { getSettings } from "../../assets/js/common.helper";
import FbAddTableRow from "./FbAddTableRow.vue";
import FbFieldConfig from "./FbFieldConfig.vue";
import { NInput } from "naive-ui";
import DeleteIconButton from "../ui/buttons/DeleteIconButton.vue";
import formBuilderService from "../../assets/services/form.builder.service";
import formMixins from "../../mixins/form.mixins";
export default {
  props: { field: {} },
  // eslint-disable-next-line vue/no-unused-components
  components: { FbFieldConfig, FbAddTableRow, DeleteIconButton },
  mixins: [formMixins],
  computed: {
    columns() {
      const vm = this;
      let columns = getSettings({ ...this.field }).columns || [];
      columns = columns.map((i) => ({
        key: i.label,
        title: i.label,
        render: (row) => {
          if (row[i.label] && i.type == "Image") {
            return h("img", {
              src: row[i.label],
              style: { width: "100px" },
            });
          } else if (i.is_editable) {
            let type = i.type == "Number" ? "number" : "text";
            return h(NInput, {
              value: row[i.label],
              type,
            });
          } else {
            return row[i.label];
          }
        },
      }));
      columns.push({
        key: "actions",
        width: "100px",
        title: "Actions",
        render(row, index) {
          return h("div", { class: "d-flex" }, [
            h(FbAddTableRow, {
              item: row,
              type: "icon",
              index: index,
              field: vm.field,
            }),
            h(DeleteIconButton, {
              size: "small",
              onClick: () => vm.remove(index),
            }),
          ]);
        },
      });
      return columns;
    },
    data() {
      let data = getSettings({ ...this.field }).table_rows || [];
      return data;
    },
  },
  methods: {
    remove(index) {
      const vm = this;
      vm.$confirm({
        message: "Are you sure, Do you want to remove this row?",
        onConfirm: async () => {
          let settings = getSettings(this.field);
          let rows = settings.table_rows || [];
          rows.splice(index, 1);
          settings.table_rows = rows;
          try {
            vm.$loader.show();
            let field = {
              ...this.field,
              Form_settings: JSON.stringify(settings),
              Dml_Indicator: "UP",
            };
            let { status, msg } = (await formBuilderService.formField(field))
              .data;
            vm.$loader.hide();
            if (!status) {
              vm.$alert(msg);
              return;
            }
            vm.fetchFormFields();
          } catch (error) {
            vm.handleError(error);
          }
        },
      });
    },
  },
};
</script>

<style></style>
